import { createContext, useContext } from 'react'

export type ExportCsvState = 'initializing' | 'ready'
export type ExportCsvContextValue = {
  state: ExportCsvState
  triggerExport: () => void
}

export const ExportCsvContext = createContext<ExportCsvContextValue | null>(
  null
)

export function useExportCsvContext(): ExportCsvContextValue {
  const context = useContext(ExportCsvContext)
  if (!context) {
    throw new Error('No ExportCsvContext available')
  }
  return context
}
