import SearchInput from '@/components/core/table/SearchInput.tsx'
import {
  FilterDrawerFilterDef,
  FiltersDrawerButton,
} from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { ExportCsvButton } from '@/components/export/ExportCsvButton.tsx'
import { ActiveFilters } from '@/components/core/drawers/filter/ActiveFilters.tsx'
import { TableModel } from '@/components/core/table/useTable.tsx'
import { useTableFilterState } from '@/components/core/table/filters/useTableFitlerState.ts'
import { BaseServiceActivity } from '@/generated'
import PagerStatus from '@/components/core/pager/PagerStatus.tsx'

type Props<T> = {
  tableModel: TableModel<T>
  filterDefs?: Array<FilterDrawerFilterDef<T>>
}
export default function ServiceActivityTableControls<
  T extends BaseServiceActivity,
>({ tableModel, filterDefs }: Readonly<Props<T>>) {
  const [tableFilterState, tableFilterStateActions] =
    useTableFilterState(tableModel)
  const disableFilterControls = tableModel.getCoreRowModel().rows.length === 0
  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-6">
      <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-gap-4 tw-text-base tw-font-normal tw-text-gray-500">
        <div className="tw-grow">
          <SearchInput
            value={tableModel.globalFilter}
            onChange={value => {
              tableModel.setGlobalFilter(String(value))
            }}
            placeholder="Search"
            disabled={disableFilterControls}
          />
        </div>
        <div className="tw-space-x-4">
          {filterDefs && (
            <FiltersDrawerButton
              filterDefs={filterDefs}
              rowData={tableModel.getCoreRowModel().rows.map(r => r.original)}
              appliedFilters={tableFilterState.activeFilters}
              disabled={disableFilterControls}
              onApplyFilters={tableFilterStateActions.applyFilters}
            />
          )}

          <Tooltip
            content={`Export current table\nview, including any\napplied filters`}
          >
            <ExportCsvButton />
          </Tooltip>
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-flex-row tw-items-start tw-justify-end tw-gap-4">
        {filterDefs && tableFilterState.hasActiveFilters && (
          <div className="tw-grow">
            <ActiveFilters
              filterDefs={filterDefs}
              appliedFilters={tableFilterState.activeFilters}
              onClearFilter={tableFilterStateActions.clearFilter}
              onClearAll={tableFilterStateActions.clearAllFilters}
            />
          </div>
        )}
        <PagerControls tableModel={tableModel} />
      </div>
    </div>
  )
}

interface PagerControlsProps<T> {
  tableModel: TableModel<T>
}

function PagerControls<T>({ tableModel }: Readonly<PagerControlsProps<T>>) {
  const itemCount = tableModel.getFilteredRowModel().rows.length
  const pageSize = tableModel.getState().pagination.pageSize
  const currentPageIndex = tableModel.getState().pagination.pageIndex
  return (
    <PagerStatus
      pageSize={pageSize}
      itemCount={itemCount}
      currentPageIndex={currentPageIndex}
    />
  )
}
