import { Moment } from 'moment/moment'
import { DailyPerformanceGraph } from '@/components/graphs/DailyPerformanceGraph.tsx'
import React, { useMemo } from 'react'
import { ISO_DATE_FORMAT, parseIsoDate } from '@/helpers/MomentHelpers.ts'
import { ColumnDef } from '@tanstack/react-table'
import numericColumn, {
  Formats,
} from '@/components/core/table/columns/numericColumn.ts'
import { DateCell } from '@/components/core/table/cells/DateCell.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import Table from '@/components/core/table/Table.tsx'
import { TotalDef, useTotals } from '@/components/core/table/useTotals.tsx'
import { Aggregations } from '@/components/core/table/aggregation.ts'
import { ActualVsExpectedCell } from '@/components/table/cells/ActualVsExpectedCell.tsx'
import { DailyPerformanceRow } from '@/generated'
import { useAssetPerformanceDailyQuery } from '@/api/AssetQueries.ts'

interface AssetDailyPerformanceProps {
  assetId: number
  minDate: Moment
  maxDate: Moment
  selectedMinDate: Moment
  selectedMaxDate: Moment
  onSelectedRangeUpdate?: (startDate: Moment, endDate: Moment) => void
}

export function AssetDailyPerformance({
  assetId,
  minDate,
  maxDate,
  selectedMinDate,
  selectedMaxDate,
  onSelectedRangeUpdate,
}: Readonly<AssetDailyPerformanceProps>): React.JSX.Element {
  const performanceQuery = useAssetPerformanceDailyQuery(
    assetId,
    minDate.format(ISO_DATE_FORMAT),
    maxDate.format(ISO_DATE_FORMAT)
  )
  const tableColumns = useMemo<ColumnDef<DailyPerformanceRow>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        cell: DateCell,
      },
      numericColumn({
        key: 'actualEnergy',
        header: 'Actual Energy (kWh)',
        format: 'integer',
      }),
      numericColumn({
        key: 'expectedEnergy',
        header: 'Expected Energy (kWh)',
        format: 'integer',
      }),
      numericColumn({
        key: 'actualVsExpectedEnergy',
        header: 'Actual vs Expected (%)',
        format: 'percent',
        cell: ActualVsExpectedCell,
      }),
    ],
    []
  )
  const allRows = useMemo(
    () => performanceQuery.data?.data ?? [],
    [performanceQuery.data]
  )
  const filteredRows = useMemo<DailyPerformanceRow[]>(
    () =>
      allRows.filter(r => {
        const rowDate = parseIsoDate(r.date)
        return (
          rowDate &&
          rowDate.isSameOrAfter(selectedMinDate) &&
          rowDate.isSameOrBefore(selectedMaxDate)
        )
      }),
    [allRows, selectedMinDate, selectedMaxDate]
  )
  const table = useTable(filteredRows, tableColumns, {
    initialSort: [{ id: 'date', desc: true }],
  })
  const totalDefs = useMemo<TotalDef<DailyPerformanceRow>[]>(
    () => [
      {
        key: 'date',
        format: () => 'TOTAL',
        className: 'tw-justify-start',
      },
      {
        key: 'actualEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'expectedEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'actualVsExpectedEnergy',
        agg: Aggregations.average,
        format: Formats.percent,
      },
    ],
    []
  )

  const totals = useTotals<DailyPerformanceRow>(filteredRows, totalDefs)

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-6">
      <DailyPerformanceGraph
        performanceRows={allRows}
        minDate={minDate}
        maxDate={maxDate}
        selectedMinDate={selectedMinDate}
        selectedMaxDate={selectedMaxDate}
        onSelectedRangeUpdate={onSelectedRangeUpdate}
      />
      <div className="tw-overflow-auto">
        <Table model={table} totals={totals} />
      </div>
    </div>
  )
}
