import { PageCard } from '@/components/cards/PageCard.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useState } from 'react'
import { useCommercialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import { useBrandingQuery } from '@/api/ApiQueries.ts'
import { CommercialServiceActivity } from '@/generated'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import { Row } from '@tanstack/react-table'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'
import PageBody from '@/pages/PageBody.tsx'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import TableDataExportCsvContextProvider from '@/components/export/TableDataExportCsvContextProvider.tsx'

const emptyServiceActivityData: CommercialServiceActivity[] = []
export default function CommercialServiceActivityPage() {
  const { data: branding } = useBrandingQuery()
  const serviceActivityQuery = useCommercialServiceActivityQuery()

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(
    ServiceActivityPageName.serviceActivity
  )
  const exportCsvColumns = useCommercialServiceActivityCsvColumns(
    ServiceActivityPageName.serviceActivity
  )
  const filterDefs = useCommercialServiceActivityTableFilters(
    ServiceActivityPageName.serviceActivity
  )
  const rows = serviceActivityQuery.data ?? emptyServiceActivityData
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
    pageSize: ServiceActivityPageSize,
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }
  return (
    <PageBody bgColor="gray">
      <div className="tw-mb-8 tw-flex tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following table shows all open service activity{' '}
            {branding?.name ? `for ${branding.name}` : ''}.
          </div>
        </div>
      </div>
      <PageCard>
        <TableDataExportCsvContextProvider
          tableModel={tableModel}
          csvOptions={{
            columns: exportCsvColumns,
            fileNamePrefix: 'service_activity',
          }}
        >
          <ServiceActivityTable
            tableModel={tableModel}
            isLoading={serviceActivityQuery.isLoading}
            isError={serviceActivityQuery.isError}
            filterDefs={filterDefs}
            handleClickRow={handleClickRow}
          />
        </TableDataExportCsvContextProvider>
        {selectedServiceActivity && (
          <ServiceActivityModal
            serviceActivity={selectedServiceActivity}
            onClose={handleCloseModal}
          />
        )}
      </PageCard>
    </PageBody>
  )
}
