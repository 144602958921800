import { Maybe, NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { stringify as toCSV } from 'csv-stringify/sync'
import moment from 'moment/moment'
import {
  ExportCsvContext,
  ExportCsvContextValue,
} from '@/components/export/ExportCsvContext.ts'
import saveAs from 'file-saver'
import { TableModel } from '@/components/core/table/useTable.tsx'

export interface CsvColumnOption<T> {
  key: Extract<NestedKeyOf<T>, string>
  header?: string
  accessorFn?: (val: T) => Maybe<string>
}

export interface TableDataExportCsvContextProviderProps<T>
  extends PropsWithChildren {
  tableModel: TableModel<T>
  csvOptions?: {
    columns: CsvColumnOption<T>[]
    fileNamePrefix: string
  }
}

// Helper function to access nested properties
function getNestedValue(obj: unknown, key: string): string {
  return (
    (key.split('.').reduce<unknown>((acc, part) => {
      if (acc && typeof acc === 'object' && part in acc) {
        return acc[part as keyof typeof acc]
      }
      return undefined
    }, obj) as string) || ''
  )
}
type ProcessedRow = Record<string, string>

export default function TableDataExportCsvContextProvider<T>({
  tableModel,
  csvOptions,
  children,
}: Readonly<TableDataExportCsvContextProviderProps<T>>) {
  const triggerExport = useCallback(() => {
    if (!csvOptions) {
      throw new Error(
        'Export triggered before csvOptions were provided. This should not be allowed.'
      )
    }
    const csv = toCSV(
      tableModel
        .getSortedRowModel()
        .rows.map(r => r.original)
        .map(row => {
          const processedRow: ProcessedRow = {}
          csvOptions.columns.forEach(column => {
            if (column.accessorFn) {
              processedRow[column.key] = column.accessorFn(row) ?? ''
            } else {
              processedRow[column.key] = getNestedValue(row, column.key)
            }
          })
          return processedRow
        }),
      {
        header: true,
        columns: csvOptions.columns,
      }
    )
    const blob = new Blob([csv], { type: 'text/x-csv' })
    const fileName =
      csvOptions.fileNamePrefix + moment().format('_YYYY-MM-DD') + '.csv'
    saveAs(blob, fileName)
  }, [tableModel, csvOptions])

  const contextValue = useMemo<ExportCsvContextValue>(
    () => ({
      state: csvOptions === undefined ? 'initializing' : 'ready',
      triggerExport,
    }),
    [csvOptions, triggerExport]
  )
  return (
    <ExportCsvContext.Provider value={contextValue}>
      {children}
    </ExportCsvContext.Provider>
  )
}
