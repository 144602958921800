import React, { useMemo, useState } from 'react'
import { Row } from '@tanstack/react-table'
import { CommercialServiceActivity } from '@/generated'
import useTable from '@/components/core/table/useTable.tsx'
import {
  useAssetMetadataQuery,
  useCommercialAssetServiceActivityQuery,
} from '@/api/AssetQueries.ts'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'

import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import assetServiceActivitySort from '@/pages/assetdetail/serviceActivity/assetServiceActivitySort.ts'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import TableDataExportCsvContextProvider from '@/components/export/TableDataExportCsvContextProvider.tsx'

export default function CommercialAssetServiceActivityTab(): React.JSX.Element {
  const { assetId } = useAssetDetailContext()
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  const serviceActivityQuery = useCommercialAssetServiceActivityQuery(
    assetId,
    true
  )

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(
    ServiceActivityPageName.assetServiceActivity
  )

  const exportCsvColumns = useCommercialServiceActivityCsvColumns(
    ServiceActivityPageName.assetServiceActivity
  )

  const filterDefs = useCommercialServiceActivityTableFilters(
    ServiceActivityPageName.assetServiceActivity
  )

  const rows = serviceActivityQuery.data?.data
  const sortedRows = useMemo<CommercialServiceActivity[] | undefined>(
    () => rows?.sort(assetServiceActivitySort),
    [rows]
  )
  const tableModel = useTable(sortedRows, columns, {
    pageSize: ServiceActivityPageSize,
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }
  const csvOptions = useMemo(() => {
    if (!assetMetadataQuery.isSuccess) {
      return
    }
    return {
      columns: exportCsvColumns,
      fileNamePrefix: `${assetMetadataQuery.data.assetName}_service_activity`,
    }
  }, [exportCsvColumns, assetMetadataQuery])

  return (
    <>
      <h2 className="tw-mb-6 tw-text-xl tw-font-bold tw-text-gray-900">
        Service Activity
      </h2>
      <TableDataExportCsvContextProvider
        tableModel={tableModel}
        csvOptions={csvOptions}
      >
        <ServiceActivityTable
          tableModel={tableModel}
          isLoading={serviceActivityQuery.isLoading}
          isError={serviceActivityQuery.isError}
          filterDefs={filterDefs}
          handleClickRow={handleClickRow}
        />
      </TableDataExportCsvContextProvider>

      {selectedServiceActivity && (
        <ServiceActivityModal
          serviceActivity={selectedServiceActivity}
          onClose={handleCloseModal}
        />
      )}
    </>
  )
}
